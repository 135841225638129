(function ($, app) {
    "use strict";

    app.m74 = function () {

        


        if($(".m74-new").length){
            var m74New = $('.m74-new').offset().top - $(window).outerHeight();
            var sliding;
            var timer = function(){
                sliding = setInterval(function(){
                    m74_next();
                },5000);
            };
            $('.slide-logo:first-child').addClass('active');
            $('.slide-content').hide();

            var tallest = 0; //Init height as 0
            $('.slide-content').each(function() { 
                // console.log($(this).outerHeight());
                if ($(this).outerHeight() > tallest) {
                    tallest = $(this).outerHeight();   
                 }
            });
            // console.log(tallest);
            $('.slide-content').each(function() {
                $(this).css('min-height', tallest+'px');
            })


            $('.slide-content:first-child').addClass('active');
            $('.slide-logo').on('click', function() {
                $('.slide-logo').removeClass('active');
                $(this).addClass('active');
                var slide = $(this).data("slide");
                $('.slide-content').removeClass('active');
                $('.slide-content.'+slide).addClass('active');
                // MAKE THE SELECTED ALWAYS ON MIDDLE WHEN CLICKED CHECK IF ITS NOT THE FIRST ELEMENT OR THE LAST ONE
                if($(this).index() > 1 && $(this).next().length != 0) {
                    $(this).prev().prev().addClass('d-none d-md-flex');
                }
                clearInterval(sliding);
                timer();
            })
            function m74_next() {
                var selected = $('.slide-logo.active');
                $('.slide-content').removeClass('active');
                if(selected.next().next().length != 0) {
                    selected.removeClass('active');
                    selected.next().addClass('active');
                    selected.prev().addClass('d-none d-md-flex');
                    selected.next().next().removeClass('d-none d-md-flex');
                    $('.slide-content.'+selected.next().data("slide")).addClass('active');
                } else if (selected.next().length != 0) {
                    selected.removeClass('active');
                    selected.next().addClass('active');
                    $('.slide-content.'+selected.next().data("slide")).addClass('active');
                } else {
                    $('.slide-logo').removeClass('d-none d-md-flex');
                    selected.removeClass('active');
                    $('.slide-logo:first-child').addClass('active');
                    $('.slide-content:first-child').addClass('active');
                }
            }

            function m74_prev() {
                var selected = $('.slide-logo.active');
                $('.slide-content').removeClass('active');


                if(selected.prev().prev().length != 0) {
                    selected.removeClass('active');
                    selected.prev().addClass('active');
                    selected.next().addClass('d-none d-md-flex');
                    selected.prev().prev().removeClass('d-none d-md-flex');
                    $('.slide-content.'+selected.prev().data("slide")).addClass('active');

                } else if (selected.prev().length != 0) {
                    selected.removeClass('active');
                    selected.prev().addClass('active');
                    $('.slide-content.'+selected.prev().data("slide")).addClass('active');

                } else {
                    $('.slide-logo').addClass('d-none d-md-flex');
                    selected.removeClass('active');
                    $('.slide-logo:last-child').addClass('active').removeClass('d-none d-md-flex').prev().removeClass('d-none d-md-flex').prev().removeClass('d-none d-md-flex');
                    $('.slide-content:last-child').addClass('active');
                }
            }



            $('.m74-nav > .next').on('click', function() {
                clearInterval(sliding);
                timer();
                m74_next();
            });
            $('.m74-nav > .prev').on('click', function() {
                clearInterval(sliding);
                timer();
                m74_prev();
            });

            // START WHEN IN VIEWPORT
            $(window).on('scroll', function(event) {
                if (m74New > $(window).scrollTop()) {
                  return;                
                }
                timer();
                $(this).off(event);
              }); 


            // setInterval(function(){ 
            //     m74_next();
            // }, 4000);





            // timer();


        };

    };
})(jQuery, app);