/* main file */

(function ($, app) {

  // Immediately (before anything goes)
  (function () {
  })();

  // When DOM is ready
  $(window).on('ready', function() {
  });

  // When everything is rendered
    $(window).on('load', function() {
        // ONLY FOR RU ON STAGING
        if ($('body.ru').length) {
          // RELOAD PAGE AFTER COOKIE ACCEPTED
          window.addEventListener("cf_init", function(){
            if(window.CookieFirst && !window.CookieFirst.consent) {
              window.addEventListener("cf_consent", function(){
                window.location.reload();
              });
            }
          });
          // SET COOKIE FUNCTION
          function ruConsent_Cookie() {
            var year = 1000*60*60*24*365;
            var expires = new Date((new Date()).valueOf() +  year);
            document.cookie = "PR-RU-Consent=true;expires=" +  expires.toUTCString() + ";path=/";
          }
          // SET COOKIE WHEN CLICKING ACCEPT BUTTON
          $('.pr-ru-consent').on('click', function() {
            ruConsent_Cookie();
            $('.ru > .cookiefirst-root').attr('style', 'visibility: visible !important');
            window.location.reload();
          });
          // CHECK COOKIE
          if (document.cookie.indexOf("PR-RU-Consent") < 0) {
            $('.cookiefirst-root').remove(); 
            setTimeout(() => { 
              $('.cookiefirst-root').remove(); 
            }, 500);
            $('#ruConsent').modal('show');
            $('#ruConsent, #TermsOfService-RU, #cookieNotice-RU, #oldTOS-RU').on('shown.bs.modal', function (e) {
              $(".modal-backdrop.fade.show").css({ opacity: 0.9 });
              console.log('test');
              
            })
          } else {
            $('.ru > .cookiefirst-root').attr('style', 'visibility: visible !important');
            setTimeout(() => { 
              $('.ru > .cookiefirst-root').attr('style', 'visibility: visible !important');
          }, 1000);
          }
          $('#TermsOfService-RU, #cookieNotice-RU, #oldTOS-RU').on('hidden.bs.modal', function () {
            new bootstrap.Modal($('#ruConsent')).show();
            
          });
          $('.modal-body a.ruPopup').on('click', function(e) {
            e.preventDefault();
            $('#TermsOfService-RU').modal("hide");
            new bootstrap.Modal($('#oldTOS-RU')).show();
          })
        }

        if (typeof $zoho != 'undefined') {
          if($("body.at, body.de, body.ch, body.sa-en, body.ae-en, body.sg, body.gb, body.au").length){
            // SET COOKIE
            function zohoIQ_cookie() {
              var year = 1000*60*60*24*365;
              var expires = new Date((new Date()).valueOf() +  year);
              document.cookie = "chat60=true;expires=" +  expires.toUTCString() + ";path=/";
            }
            if (document.cookie.indexOf("chat60") < 0) {
              // HIDE CHAT
              $zoho.salesiq.floatbutton.visible("hide");
              // SET COOKIE & SHOW CHAT AFTER 60s
              setTimeout( function(){
                zohoIQ_cookie();
                $zoho.salesiq.floatbutton.visible("show");
              }, 60000)
            }
          }
          setTimeout( function(){
            $(".zsiq_theme1.zsiq_floatmain").css("right", "10px");
          }, 2000);
        }
        app.setDimensions();
        app.AddClassScroll();
        app.SlickSliderSetup();
        // app.smoothScroll();
        app.header();
        
        app.m01();
        app.m01c();
        app.m01d();
        app.m03();
        app.m12();
        app.m17();
        app.m20();
        app.m21();
        app.m22();
        app.m23();
        app.m25();
        app.m29();
        app.m29v2();
        app.m30();
        app.m31();
        app.m32();
        app.m36();
        app.m37();
        app.m40();
        app.m43();
        app.m44();
        app.m50();
        app.m51();
        app.m52();
        app.m54();
        app.m55();
        app.m57();
        app.m59();
        app.m62();
        app.m63();
        app.m64();
        app.m68();
        app.m69();
        app.m72();
        app.m73();
        app.m74();
        app.m76();
        app.m78();
        app.m80();
        app.m81();
        app.m85();
        app.m89();
        app.m90();
        app.m91();
        app.m94();
        app.m97();
        app.m100()
        app.m101();
        app.m102();
        app.w01();
        app.webopRegistration();

        app.event_form();
        app.author();
        app.select2Setup();
        
        //registration form show loading
        $('.form-loading').addClass('d-none');
        $('.form-loaded').removeClass('d-none');

        $('.pr-form-loading').addClass('invisible');
        $('.pr-form-loaded').removeClass('invisible');

        app.kb();
        app.single();

        // Set Menu herf value
        //$('a[href^="#"]:not(.kb-title, .kb-child-title, .qsg-item, .blog_post_box, .qg_mobile_menu_header,.QSG_menu .menu-item a, .post-content a, .cta_btn a, article a)').each(function(){ 
        $('.menu-item-link[href^="#"]').each(function(){ 
          $(this).attr("href", "javascript:void(0);"); 
        });
        // Disable default select
        $(document).bind('mobileinit',function(){
          $.mobile.selectmenu.prototype.options.nativeMenu = false;
        });
        if (document.querySelector('.registrationForm') !== null || document.querySelector('.m72') !== null) {
          window.addEventListener("cf_consent", function(event) {
            location.reload();
          });
        }

        // SET COUNTRY FOR CONTACT FORMS 
        if (document.querySelector('.contact-select-country') !== null) {
          function setCountry() {
            // for local test add https://www.cloudflare.com
            fetch('/cdn-cgi/trace').then(res => res.text() ).then(t => {
                var data = t.replace(/[\r\n]+/g, '","').replace(/\=+/g, '":"');
                    data = '{"' + data.slice(0, data.lastIndexOf('","')) + '"}';
                var jsondata = JSON.parse(data);
                // old way used value to select / didn't work with countries with the same value
                // var selectedCountry = $('.selectpicker.contact-select-country option[data-icon="fi fi-'+jsondata.loc.toLowerCase()+'"]').val();
                // $('.selectpicker.contact-select-country').val(selectedCountry);
                $(".selectpicker.contact-select-country option[data-icon='fi fi-"+jsondata.loc.toLowerCase()+"']").prop("selected", true);
                $('.selectpicker.contact-select-country').selectpicker('refresh');
            })
          }
          setCountry();          
        }

        $('.etracker-optout').on('click', function() {
          $('.etracker-opt-out').removeClass('d-none');
        });
    });
  

})(jQuery, app);