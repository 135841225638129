(function ($, app) {
  "use strict";

  app.m54 = function () {


    $('.selectpicker').selectpicker();
    if (document.querySelector('.registration') !== null) {
      $(".selectpicker.select-country option[data-icon='fi fi-"+country.toLowerCase()+"']").prop("selected", true);
      $('.selectpicker.select-country').selectpicker('refresh');
    }
    $('#mail2').on('change', function() {
      $('#mail').val($(this).val());
    });

    $("#promo").click(function() {
      if($(this).is(":checked")) {
          $(".promo-code").show(300);
      } else {
          $(".promo-code").hide(200);
      }
    });

    if ($(".registration-new")[0]){
      $(window).scroll(function() {
         if ($(document).scrollTop() > 100) {
             $('.stickForm').addClass('stickyBottom')
         } else {
             $('.stickForm').removeClass('stickyBottom')
         }
      });
    }
    $('#sb-showInfo').on('click', function() {
        $('.sidebar-moreinfo').toggleClass('d-none')
    });



    //SHOW/HIDE PASSOWRD
    $("body").on('click', '.toggle-password', function() {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $("#password");
      if (input.attr("type") === "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
  
    });
    //CONSULTATION
    $(".consultation-time").hide();
    $("#consultation").click(function() {
        if($(this).is(":checked")) {
            $(".consultation-time").show(300);
            $(".timeslot").prop('required',true);
        } else {
            $(".consultation-time").hide(200);
            $(".timeslot").prop('required',false);
        }
    });

    //BROWSER TEST
    function testBrowser(browserString) {
      return navigator.userAgent.toLowerCase().indexOf(browserString) > -1;
    }

    

    if (document.querySelector('.g-recaptcha') !== null) {
      window.recaptcha_callback = recaptcha_callback;
      function recaptcha_callback(){
        $('.create_account').removeClass('disabled');
      }
    }


    function find_cookie(name) {
      var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
      if (match) return match[2];
    }



    var signUpForm = $('.registrationForm');
    var nextBlock  = $('.next_block');
    var prevBlock  = $('.prev_block');
    var createAcc  = $('.create_account');


    signUpForm.parsley({
        errorClass: 'is-invalid',
        successClass: 'parsley-success',
        errorsWrapper: '<span class="text-help">',
        errorTemplate: '<div></div>'
    });

      //CHECKING EMAIL
    if (document.querySelector('.registrationForm') !== null) {
      window.Parsley.addAsyncValidator('emailChecker', function (xhr) {
        try {
          var response = JSON.parse(xhr.responseText);
          if (response.exists === true) {
            return $.Deferred().reject(mailerror);
          }
          else {
            return true;
          }
        } catch(e) {
          return false
        }
        return false;
      }, checkmail);
    }
      var $sections = $('.form-section');

      function navigateTo(index) {
        // Mark the current section with the class 'current'
        $sections
          .removeClass('current')
          .eq(index)
            .addClass('current');
        // Show only the navigation buttons that make sense for the current section:
        prevBlock.toggle(index > 0);
        var atTheEnd = index >= $sections.length - 1;
        nextBlock.toggle(!atTheEnd);
        createAcc.toggle(atTheEnd);
      }
    
      function curIndex() {
        // Return the current index by looking at which section has the class 'current'
        return $sections.index($sections.filter('.current'));
      }
    
      // Previous button is easy, just go back
      prevBlock.click(function() {
        navigateTo(curIndex() - 1);
      });
    
      // Next button goes forward iff current block validates
      nextBlock.click(function() {
        if ($(".registration-new")[0]){
          $('.stickForm').addClass('step2scroll');
        }
      signUpForm.parsley().whenValidate({
          group: 'block-' + curIndex()
        }).done(function() {
          navigateTo(curIndex() + 1);
        });
      });
    
      // Prepare sections by setting the `data-parsley-group` attribute to 'block-0', 'block-1', etc.
      $sections.each(function(index, section) {
        $(section).find(':input').attr('data-parsley-group', 'block-' + index);
      });
      navigateTo(0); // Start at the beginning



      let cj_action_id = 404603
      let gclid        = find_cookie("gclid")
      let reg_source   = find_cookie("source")
      let ad_id_kwd    = find_cookie("ad_id_kwd")
      let adjust_id_c  = find_cookie("adjust_id")
      let hsutk        = find_cookie("hubspotutk")
      let ncookies     = {
        "pr_ft_utm_source":find_cookie("pr_ft_utm_source"),
        "pr_ft_utm_medium":find_cookie("pr_ft_utm_medium"),
        "pr_ft_utm_campaign":find_cookie("pr_ft_utm_campaign"),
        "pr_ft_utm_content":find_cookie("pr_ft_utm_content"),
        "pr_ft_utm_term":find_cookie("pr_ft_utm_term"),
        "pr_lt_utm_source":find_cookie("pr_lt_utm_source"),
        "pr_lt_utm_medium":find_cookie("pr_lt_utm_medium"),
        "pr_lt_utm_campaign":find_cookie("pr_lt_utm_campaign"),
        "pr_lt_utm_content":find_cookie("pr_lt_utm_content"),
        "pr_lt_utm_term":find_cookie("pr_lt_utm_term"),
      }

      if (typeof cj_enterprise_id !== "undefined" && reg_source.substr(reg_source.length-2) == "cj") {
        $.ajax({
            url: 'https://www.emjcd.com/u?AMOUNT=0&CID=5045712&OID='+ data.idhash + '&TYPE='+ cj_action_id + '&CURRENCY=EUR&METHOD=IMG',
          });
      }
      
      //ACCOUNT CREATION
      createAcc.on('click', function(event) {
        signUpForm.parsley().whenValidate().done(function() {
          //SHOW LOADING
          $('.form-loaded').addClass('d-none');
          $('.form-loading').removeClass('d-none');
          

          //HIDE ERROR MESSAGE IF SHOWN
          $('.signupError').addClass('d-none');  
          let form_data = {
            "g-recaptcha-response" : $("#g-recaptcha-response").val(),
            "data": {
              "attributes": {
                "mail": $("#mail").val(),
                "source": reg_source,
                "sales_code": gclid,
                "ad_id_kwd": ad_id_kwd,
                "adjust_id": adjust_id_c,
                "selected_cookies": ncookies,
                "locale": $("#locale").val(),
                "firstname": $("#firstname").val(),
                "lastname": $("#lastname").val(),
                "phone_number": $('#country_code').val() + $("#phone_number").val(),
                "password": $("#password").val(),
                "retype_password": $("#password").val(),
                "company_name": $("#company_name").val(),
                "sales_code": ($("#promocode").val()) ? $("#promocode").val() : gclid,
                "sales_code": $("#promocode").val(),
                "terms": true,
                "newsletter": $("#newsletter").is(":checked"),
                "contact_via_phone": $("#consultation").is(":checked"),
                "contact_when": $("#timeslot").val(),
                "referral_code": $("#referral").val(),
                "demo_project_type":  $("#position").val(),
                "wplocale": wplocale,
              }
            }
          }
          if((wplocale == 'ru-RU') || (country == 'RU')) {
            $.post(register, form_data).done(function(results) {
              let data = results.data.attributes;
              if (typeof ga !== "undefined") {
                ga('send', { hitType: 'event', eventLabel: 'user_registration'});
              }
    
              if (typeof fbq !== "undefined" && ad_id_kwd != undefined && ad_id_kwd.toLowerCase().indexOf("facebook") >= 0) {
                fbq('track', 'Lead', {value: 0.00, currency: 'EUR'});
              }
              
              if(testBrowser('mobile-registration-from-android')) {
                window.location.href = login + '?uuid=' + data.uuid + '&app=android&demo_project_type=' + $("#position").val();
              }
              else if(testBrowser('mobile-registration-from-ios')) {
                window.location.href = login +'?uuid=' + data.uuid + '&app=ios&demo_project_type=' + $("#position").val();
              }
              else if(window.location.href.indexOf("app=true") > -1)
              {
                window.location.href = login +'?uuid=' + data.uuid + '&app=true&demo_project_type=' + $("#position").val();
              }
    
              else {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({'event':'testaccount', 'eventCategory':'testaccount', 'eventAction': 'sent', 'eventLabel': 'user_registration'});
                window.location.href = login +'?uuid=' + data.uuid + '&demo_project_type=' + $("#position").val();
              }
              
            }).fail(function(data) {
              //If failed hide loading
              $('.form-loaded').addClass('d-none');
              $('.form-loading').removeClass('d-none');
              //DISPLAY ERROR MESSAGE
              $('.signupError').removeClass('d-none');
              
            });
          } else {
            // let RegistrationFormData = JSON.stringify(form_data);
            $.post(register, form_data)
            // $.ajax({
            //   type: "POST",
            //   beforeSend: function(xhr) {
            //     xhr.setRequestHeader( "Content-type", "application/json" );
            //   },
            //   url: register,
            //   data: RegistrationFormData,
            //   dataType: "json"
            // })
            .done(function(results) {
              let data = results.data.attributes;
              if (typeof ga !== "undefined") {
                ga('send', { hitType: 'event', eventLabel: 'user_registration'});
              }
    
              if (typeof fbq !== "undefined" && ad_id_kwd != undefined && ad_id_kwd.toLowerCase().indexOf("facebook") >= 0) {
                fbq('track', 'Lead', {value: 0.00, currency: 'EUR'});
              }
              const dach = ["DE", "CH", "AT"];
              if (dach.includes(country) && (typeof tracking_usr_id !== "undefined")) {
                $.getScript("https://ilead.itrack.it/clients/DEtracking/c19059/planradar.asp?px=js&type=soi&order_id="+data['user-id']+ "&ts=" + $.now());
              }

              let hs_form = {
                "fields": [
                  {
                    "name": "email",
                    "value": $("#mail").val()
                  },
                  {
                    "name": "company",
                    "value": $("#company_name").val()
                  },
                  {
                    "name": "firstname",
                    "value": $("#firstname").val()
                  },
                  {
                    "name": "lastname",
                    "value": $("#lastname").val()
                  },
                  {
                    "name": "phone",
                    "value": $('#country_code').val() + $("#phone_number").val()
                  },
                  {
                    "name": "first_login_language",
                    "value": $("#locale").val()
                  },
                  {
                    "name": "user_id",
                    "value": data['user-id']
                  }
                ],
                "context": {
                  "hutk"    : hsutk, // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
                  "pageUri" : window.location.href,
                  "pageName": "Registration"
                },
              }
              let hs_url = "https://api.hsforms.com/submissions/v3/integration/submit/7061715/57563cc5-8ee7-4c45-9df8-94b7210ec494";
              let hs_form_data = JSON.stringify(hs_form);
              $.ajax({
                type: "POST",
                beforeSend: function(xhr) {
                  xhr.setRequestHeader( "Content-type", "application/json" );
                },
                url: hs_url,
                data: hs_form_data,
                dataType: "json"
              }).always(function() {
                //REDIRECTION [ALWAYS V1]
                if(testBrowser('mobile-registration-from-android')) {
                  window.location.href = login + '?uuid=' + data.uuid + '&app=android&demo_project_type=' + $("#position").val();
                }
                else if(testBrowser('mobile-registration-from-ios')) {
                  window.location.href = login +'?uuid=' + data.uuid + '&app=ios&demo_project_type=' + $("#position").val();
                }
                else if(window.location.href.indexOf("app=true") > -1)
                {
                  window.location.href = login +'?uuid=' + data.uuid + '&app=true&demo_project_type=' + $("#position").val();
                }
      
                else {
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({'event':'testaccount', 'eventCategory':'testaccount', 'eventAction': 'sent', 'eventLabel': 'user_registration'});
                  window.location.href = login +'?uuid=' + data.uuid + '&demo_project_type=' + $("#position").val();
                }
              });
            }).fail(function(data) {
              //If failed hide loading
              $('.form-loaded').addClass('d-none');
              $('.form-loading').removeClass('d-none');
              //DISPLAY ERROR MESSAGE
              $('.signupError').removeClass('d-none');
              
            });
          }



        });
  
    });
  };
})(jQuery, app);