/* responsive module */

(function ($, app) {
    "use strict";

    app.m69 = function () {
        //Cookie funtion. need to move
        function getCookie(name) {
            var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
            if (match) return match[2].slice(-250);
            else return 'undefined';
          }

        $('.hsfrom').parsley({
          errorClass: 'is-invalid',
          successClass: 'parsley-success',
          errorsWrapper: '<span class="text-help">',
          errorTemplate: '<div></div>'
        });

        var transliterate = function(text) {
          var letters = {
            // AR
            'ء':'e',
            'آ':'a',
            'أ':'a', 
            'ؤ':'w',
            'إ':'i',
            'ئ':'y',
            'ا':'a',
            'ب':'b',
            'ة':'t',
            'ت':'t',
            'ث':'th',
            'ج':'j',
            'ح':'h',
            'خ':'kh',
            'د':'d',
            'ذ':'dh',
            'ر':'r',
            'ز':'z',
            'س':'s',
            'ش':'sh',
            'ص':'s',
            'ض':'d',
            'ط':'t',
            'ظ':'z',
            'ع':'e',
            'غ':'gh',
            'ـ':'_',
            'ف':'f',
            'ق':'q',
            'ك':'k',
            'ل':'l',
            'م':'m',
            'ن':'n',
            'ه':'h',
            'و':'w',
            'ى':'a',
            'ي':'y',
            // RU
            'А':'A',
            'а':'a',
            'Б':'B',
            'б':'b',
            'В':'V',
            'в':'v',
            'Г':'G',
            'г':'g',
            'Д':'D',
            'д':'d',
            'ъе':'ye',
            'Ъе':'Ye',
            'ъЕ':'yE',
            'ЪЕ':'YE',
            'Е':'E',
            'е':'e',
            'Ё':'Yo',
            'ё':'yo',
            'Ж':'Zh',
            'ж':'zh',
            'З':'Z',
            'з':'z',
            'И':'I',
            'и':'i',
            'ый':'iy',
            'Ый':'Iy',
            'ЫЙ':'IY',
            'ыЙ':'iY',
            'Й':'Y',
            'й':'y',
            'К':'K',
            'к':'k',
            'Л':'L',
            'л':'l',
            'М':'M',
            'м':'m',
            'Н':'N',
            'н':'n',
            'О':'O',
            'о':'o',
            'П':'P',
            'п':'p',
            'Р':'R',
            'р':'r',
            'С':'S',
            'с':'s',
            'Т':'T',
            'т':'t',
            'У':'U',
            'у':'u',
            'Ф':'F',
            'ф':'f',
            'Х':'Kh',
            'х':'kh',
            'Ц':'Ts',
            'ц':'ts',
            'Ч':'Ch',
            'ч':'ch',
            'Ш':'Sh',
            'ш':'sh',
            'Щ':'Sch',
            'щ':'sch',
            'Ъ':'',
            'ъ':'',
            'Ы':'Y',
            'ы':'y',
            'Ь':'',
            'ь':'',
            'Э':'E',
            'э':'e',
            'Ю':'Yu',
            'ю':'yu',
            'Я':'Ya',
            'я':'ya',
          }
          return text.split('').map(function (char) { 
            return letters[char] || char; 
          }).join('');
        };
        

        $(".hubspot-form").on("submit", function(e){
            e.preventDefault();
            $('.form-loaded').addClass('d-none');
            $('.form-loading').removeClass('d-none');
            
            var hsFormID  = $(this).attr("data-hs");
            var Env       = $(this).attr("data-env");

            //COOKIES
            var lt_medium   = getCookie("pr_lt_utm_medium");
            var lt_source   = getCookie("pr_lt_utm_source");
            var lt_campaign = getCookie("pr_lt_utm_campaign");
            var lt_content  = getCookie("pr_lt_utm_content");
            var lt_term     = getCookie("pr_lt_utm_term");
            var ft_medium   = getCookie("pr_ft_utm_medium");
            var ft_source   = getCookie("pr_ft_utm_source");
            var ft_campaign = getCookie("pr_ft_utm_campaign");
            var ft_content  = getCookie("pr_ft_utm_content");
            var ft_term     = getCookie("pr_ft_utm_term");
            var source      = getCookie("source");
            var ad_id_kwd   = getCookie("ad_id_kwd");
            var hsutk       = getCookie("hubspotutk");
            if (hsutk == 'undefined') {
              hsutk = null;
            }


          let $fields = [
            {
              "name": "campaign_first_touch_",
              "value": ft_campaign
            },
            {
              "name": "content_first_touch_",
              "value": ft_content
            },
            {
              "name": "medium_first_touch_",
              "value": ft_medium
            },
            {
              "name": "source_first_touch_",
              "value": ft_source
            },
            {
              "name": "term_first_touch_",
              "value": ft_term
            },
            {
              "name": "campaign_last_touch_",
              "value": lt_campaign
            },
            {
              "name": "content_last_touch_",
              "value": lt_content
            },
            {
              "name": "medium_last_touch_1",
              "value": lt_medium
            },
            {
              "name": "source_last_touch_",
              "value": lt_source
            },
            {
              "name": "term_last_touch_",
              "value": lt_term
            },
            {
              "name": "lead_source_detail",
              "value": source
            },
            {
              "name": "ad_id_keyword",
              "value": ad_id_kwd
            }
          ];


          $('.form-input').each(function(){
            let $obj = {};
            if (typeof $(this).attr('name') != "undefined") {
              $obj['name'] = $(this).attr('name');
              $obj['value'] = ($(this).attr('type')=='checkbox')? 'Yes' : 
                              ($(this).hasClass('input-add-country'))? $('#country_code').val()+$(this).val() : 
                              ($(this).is('textarea'))? $(this).val() : 
                              (Env == 'http://planradar.test' || Env == 'https://staging.defectradar.com')? transliterate($(this).val()) :
                              $(this).val();
              $fields.push($obj);
            }
          });
            var hs_form = {
                "fields": $fields,
                "context": {
                  "hutk"    : hsutk, // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
                  "pageUri" : window.location.href,
                  "pageName": ( typeof pageTitle !== 'undefined') ? pageTitle : "Events"
                },
              }
            var hs_url = "https://api.hsforms.com/submissions/v3/integration/submit/7061715/"+hsFormID;
            var hs_form_data = JSON.stringify(hs_form)
            $.ajax({
              type: "POST",
              beforeSend: function(xhr) {
                xhr.setRequestHeader( "Content-type", "application/json" );
              },
              url: hs_url,
              data: hs_form_data,
              dataType: "json"
            }).done( function() {
                if (hsFormID == 'c324b04d-ad45-4f5f-8ca8-95578e68d8fa') {
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    'event': 'contact-us'
                  });
                }
                $('.form-loading').addClass('d-none');
                $('.form-thanks').removeClass('d-none');
            })
        });


    };
})(jQuery, app);